import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() { }

  @Input() blogData: Array<{
    image: string;
    title: string;
    id: number;
  }>;

  projectData = [
    {
      id: 1,
      title: 'KURIER EXPRESS',
      subtitle: 'Business web pages with multiple platforms',
      description: '/project',
    },
    {
      id: 2,
      title: 'TCKurier & TMKurier',
      subtitle: 'Best mobile applications for Android & IOS for Business',
      description: '/project',
    },
    {
      id: 3,
      title: 'CESIRO',
      subtitle: 'Smartest Applications for Business',
      description: '/project',
    }
  ];

  ngOnInit(): void {
  }

}
