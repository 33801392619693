
<div class="row">
    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of blogData">
        <a [routerLink]="['/project', data.id]" class="text-muted readmore">
        <div class="card blog rounded border-0 shadow">
            <div class="position-relative">
                <img src="{{data.image}}" class="card-img-top rounded-top" alt="...">
                <div class="overlay rounded-top bg-dark"></div>
            </div>
            <div class="card-body content">
                <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.title}}</a>
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                    <p class="text-muted readmore"> {{'SECTION6.READMORE' | translate}} <i class="uil uil-angle-right-b"></i> </p>
                </div>
            </div>
        </div>
        </a>
    </div>
</div>

