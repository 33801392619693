<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
<!--                        <a href="#" class="logo-footer">-->
<!--                            <img src="assets/images/logo-dark.png" height="24" alt="">-->
<!--                        </a>-->
                        <p class="mt-4 text-muted">{{'FOOTER.DESCRIPTION' | translate}}</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">{{'FOOTER.MENU' | translate}}</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a (click)="scroll('home')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.HOME' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('whatwedo')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i>{{'FOOTER.WHATWEDO' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('whoweare')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.WHOWEARE' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('howwedowork')"  class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.HOWWEWORK' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('ourprojects')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.PROJECTS' | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">{{'FOOTER.LINKS' | translate}}</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a [routerLink]="['/page-terms']" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                {{'FOOTER.TERMS' | translate}}</a></li>
                            <li><a [routerLink]="['/page-privacy']" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                {{'FOOTER.POLICY' | translate}}</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">{{'FOOTER.CONTACT' | translate}}</h5>
                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.EMAIL' | translate}} </h7>
                                <a href="mailto:contact@example.com" class="text-primary">contact@funit.com</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.PHONE' | translate}} </h7>
                                <a href="tel:+152534-468-854" class="text-primary">+40 770 197 675</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.LOCATION.NAME' | translate}} </h7>
                                <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">
                                    {{'FOOTER.LOCATION.VIEW' | translate}}
                                </a>
                                <ng-template #content let-modal>
                                    <div class="modal-header">
                                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <iframe
                                                src="https://maps.google.com/maps?q=Strada%20Tarnita%205e,%20Alba%20Iulia&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                                height="450" width="750">
                                        </iframe>
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="star" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0"> {{'FOOTER.NAME' | translate}}</h7>
                                <a href="" class="text-primary">Fun IT Service</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="file-text" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.CUI' | translate}}</h7>
                                <a href="" class="text-primary">44540584</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
<!--                        <a href="#" class="logo-footer">-->
<!--                            <img src="assets/images/logo-light.png" height="24" alt="">-->
<!--                        </a>-->
                        <p class="mt-4">{{'FOOTER.DESCRIPTION' | translate}}</p>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                    </div>

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">{{'FOOTER.MENU' | translate}}</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a (click)="scroll('home')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.HOME' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('whatwedo')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.WHATWEDO' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('whoweare')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.WHOWEARE' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('howwedowork')"  class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.HOWWEWORK' | translate}}
                                </a>
                            </li>
                            <li>
                                <a (click)="scroll('ourprojects')" class="text-foot cursor">
                                    <i class="uil uil-angle-right-b me-1"></i> {{'FOOTER.PROJECTS' | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">{{'FOOTER.LINKS' | translate}}</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a [routerLink]="['/page-terms']"  class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                {{'FOOTER.TERMS' | translate}}</a></li>
                            <li><a [routerLink]="['/page-privacy']" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                {{'FOOTER.POLICY' | translate}}</a></li>
                            <li><a [routerLink]="['/page-cookie']" class="text-foot"><i
                                    class="uil uil-angle-right-b me-1"></i>
                                Cookie Policy</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">{{'FOOTER.CONTACT' | translate}}</h5>
                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.EMAIL' | translate}}</h7>
                                <a href="mailto:contact@example.com" class="text-primary">contact@example.com</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.PHONE' | translate}} </h7>
                                <a href="tel:+152534-468-854" class="text-primary">+40 770 197 675</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.LOCATION.NAME' | translate}} </h7>
                                <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary" style="cursor: pointer">
                                    {{'FOOTER.LOCATION.VIEW' | translate}}
                                </a>
                                <ng-template #content let-modal>
                                    <div class="modal-header">
                                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <iframe
                                                src="https://maps.google.com/maps?q=Strada%20Tarnita%205e,%20Alba%20Iulia&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                                height="450" width="750">
                                        </iframe>
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="star" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.NAME' | translate}}</h7>
                                <a href="" class="text-primary">Fun IT Service</a>
                            </div>
                        </div>

                        <div class="d-flex contact-detail align-items-center mt-3">
                            <div class="icon">
                                <i-feather name="file-text" class="fea icon-m-md text-dark me-3"></i-feather>
                            </div>
                            <div class="flex-1 content">
                                <h7 class="title fw-bold mb-0">{{'FOOTER.CUI' | translate}} </h7>
                                <a href="" class="text-primary">44540584</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    </ng-template>
</div>
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">
                        <script type="text/javascript" id="www-widgetapi-script"
                                src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                                async="">
                        </script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script>Copyright
                        <a href="javascript:void(0);" class="text-reset">Fun IT Service</a> © {{year}}
                    </p>
                </div>
            </div>

        </div>
    </div>
</footer>
