<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container" id="nav">
    <!-- Logo container-->
    <div>
<!--      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">-->
<!--        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="24" alt="">-->
<!--        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="24" alt="">-->
<!--      </a>-->

<!--      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">-->
<!--        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">-->
<!--        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">-->
<!--      </a>-->
    </div>

    <span *ngIf="!buttonList && !isdeveloper && !shopPages">
      <div class="buy-button">
        <ng-template [ngIf]="navClass === 'nav-light'">
          <a>
          <img  style="height: 20px; cursor: pointer"
               src="assets/img_funIt/romania2.svg" class="avatar avatar-small"
               (click)="useLanguage('ro')" alt="">
          </a>
          <a>
          <img style="height: 20px; margin-left: -29px; cursor: pointer"
               src="assets/img_funIt/united-kingdom2.svg" class="avatar avatar-small" alt=""
               (click)="useLanguage('en')">
          </a>
<!--          <a href="" class="btn btn-primary login-btn-primary">Company</a>-->
<!--          <a href="" class="btn btn-light login-btn-light">Company</a>-->
        </ng-template>
        <ng-template [ngIf]="navClass !== 'nav-light'">
          <a>
          <img style="height: 20px; cursor: pointer"
               src="assets/img_funIt/romania2.svg" class="avatar avatar-small"
               (click)="useLanguage('ro')" alt="">
          </a>
          <a>
          <img style="height: 20px; cursor: pointer"
               src="assets/img_funIt/united-kingdom2.svg" class="avatar avatar-small" alt=""
               (click)="useLanguage('en')">
          </a>
<!--          <a href="" class="btn btn-primary">Company</a>-->
        </ng-template>
      </div>
    </span>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a (click)="scroll('home')"  class="nav-link-ref cursor" >{{'HEADER.HOME' | translate}}</a></li>

        <li class="navigation-menu">
          <a (click)="scroll('whatwedo')" class="cursor"> {{'HEADER.WHATWEDO' | translate}}</a><span class="menu-arrow"></span>
        </li>

        <li class="navigation-menu">
          <a (click)="scroll('whoweare')" class="cursor">{{'HEADER.WHOWEARE' | translate}}</a><span class="menu-arrow"></span>
        </li>

        <li class="navigation-menu">
          <a (click)="scroll('howwedowork')" class="cursor">{{'HEADER.HOWWEWORK' | translate}}</a><span class="menu-arrow"></span>
        </li>

        <li class="navigation-menu">
          <a (click)="scroll('ourprojects')" class="cursor">{{'HEADER.PROJECTS' | translate}}</a><span class="menu-arrow"></span>
        </li>
      </ul>
      <!--end navigation menu-->
      <div class="d-none">
<!--        <a href="" class="btn btn-primary">Company</a>-->
      </div>

    </div>
  </div>
</header>

