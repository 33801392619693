import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { ScrollspyDirective } from './scrollspy.directive';
import { FeatherModule } from 'angular-feather';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { BlogComponent } from './blog/blog.component';
import { MemberComponent } from './member/member.component';
import {TranslateModule} from '@ngx-translate/core';
import {ProjectComponent} from '../core/components/project/project.component';

@NgModule({
  declarations: [
    ScrollspyDirective,
    TestimonialComponent,
    BlogComponent,
    MemberComponent,
    ProjectComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    FeatherModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [TestimonialComponent,
    ScrollspyDirective,
    BlogComponent,
    MemberComponent,
  ]
})

export class SharedModule { }
