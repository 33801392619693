import {Project} from './components/project/model/project.model';
// 'Business Web pages with multiple platforms'
export class ProjectService {
    allData: {id: number, data: Project}[] = [
        {id: 1, data: new Project(
                'KURIER EXPRESS',
                'projects.subtitle.project1',
                'projects.description.project1',
                ['Java', 'PHP', 'CodeIgniter', 'Intellij', 'PhpMyAdmin', 'tec6'],
                ['../../../assets/img_funIt/kurier1.png', '../../../assets/img_funIt/kurier2.png', '../../../assets/img_funIt/kurier3.png', '../../../assets/img_funIt/kurier4.png'],
                ['primary', 'danger', 'success', 'secondary', 'warning', 'info'],
                ['Digital Marketing Solutions for Tomorrow', 'Our Talented &amp; Experienced Marketing Agency', 'Create your own skin to match your brand'],
                ['Mobile apps', 'Smartest projects for business'],
                [2, 3],
                ['../../../assets/img_funIt/app1.Resize.png', '../../../assets/img_funIt/app1.Resize.png'],
                'https://kurierexpress.ro/')},
        {id: 2, data: new Project(
                'TCKurier & TMKurier',
                'projects.subtitle.project2',
                'projects.description.project2',
                ['Dart', 'Flutter'],
                ['../../../assets/images/app0.png', '../../../assets/images/app1.png', '../../../assets/images/app2.png'],
                ['primary', 'success', 'danger', 'secondary', 'warning', 'info'],
                ['Digital Marketing Solutions for Tomorrow'],
                ['Projects with multiple platforms', 'Smartest projects for business'],
                [1, 3],
                ['../../../assets/img_funIt/kurierResize.jpg', '../../../assets/img_funIt/app1.Resize.png'],
                'https://kurierexpress.ro/')},
        {id: 3, data: new Project(
                'CESIRO',
                'projects.subtitle.project3',
                'projects.description.project3',
                ['tech1', 'tech2'],
                ['../../../assets/images/cesiro1.png', 'img2'],
                ['primary', 'info', 'danger', 'secondary', 'warning', 'success'],
                ['Our Talented &amp; Experienced Marketing Agency', 'Create your own skin to match your brand'],
                ['Projects with multiple platforms', 'Mobile apps'],
                [1, 2],
                ['../../../assets/img_funIt/kurierResize.jpg', '../../../assets/img_funIt/app1.Resize.png'],
                'https://kurierexpress.ro/')},
    ];

    getProject(id: number): Project {
        const project = this.allData.find(
            (s) => {
                return s.id === id;
            }
        );
        return project.data;
    }
}
