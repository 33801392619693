<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h2 class="text-primary fw-bold">{{data.title}}</h2>
          <h4 class="text-primary fw-bold">{{data.subtitle | translate}}</h4>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section pb-0" style="margin-bottom: 150px; padding: 30px" >
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide *ngFor="let element of data.images index as i;">
            <div class="tiny-slide">
              <div class="tiny-slide" ><img src="{{data.images[i]}}" class="img-fluid rounded" alt=""></div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>

      <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-md-4">
          <h4 class="text-primary fw-bold">{{data.title}}</h4>

          <h5 class="mt-4 py-2">{{'projects.description.title' | translate}}</h5>
          <p class="text-muted">{{data.description | translate}}</p>

          <h5 class="mt-4 py-2">{{'projects.description.title2' | translate}}</h5>
          <h4 class="d-inline " *ngFor="let element of data.technologies index as i;"><span class="badge bg-soft-{{data.badge[i]}}">{{data.technologies[i]}}</span></h4>
          <hr>

          <a href="{{data.linkSite}}">
            <h4>
              <i-feather name="arrow-right" class=""></i-feather>{{'projects.link' | translate}}
              <i-feather name="arrow-left" class="icons"></i-feather>
            </h4>
          </a>
          <hr>
          <ul class="list-unstyled text-muted" *ngFor="let element of data.check index as i;">
            <li class="mb-0">
              <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle">
                </i>
              </span> {{data.check[i]}}
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container" style="margin-top: 80px;" >
    <div class="row justify-content-center">
      <!-- BLog Start -->
      <div class="col-lg-7 text-center">
        <div class="card shadow rounded border-0 mt-4">
          <div class="card-body" >
            <h5 class="card-title mb-0">Related Posts:</h5>
            <div class="row">
              <div class="col-lg-6 mt-4 pt-2" *ngFor="let element of data.related index as i;">
                <a [routerLink]="['/project', data.id2[i]]"  class="text-muted readmore">
                <div class="card blog rounded border-0 shadow">
                  <div class="position-relative">
                    <img src="{{data.imgRelated[i]}}" class="card-img-top rounded-top" alt="...">
                    <div class="overlay rounded-top bg-dark"></div>
                  </div>
                  <div class="card-body content">
                    <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.related[i]}}
                    </a>
                    </h5>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <p  class="text-muted readmore">{{'SECTION6.READMORE' | translate}}<i class="uil uil-angle-right-b align-middle"></i></p>
                    </div>
                  </div>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</section>


<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
