<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Politica de protecție a datelor cu caracter personal </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised:</span> 7th Jul,
              2022</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <p>Societatea XXXX SRL, cu sediul social în Alba Iulia, str. Tarnita, 5e,
            jud. Alba, înmatriculată la Oficiul Registrului Comerțului de pe lângă Tribunalul Alba sub nr. XXXX, având
            CUI 44540584, în calitate de Operator de date cu caracter personal (denumită în continuare „Societatea” sau
            „Operatorul”), respectă întocmai prevederile legale în vigoare privind protecția persoanelor cu privire
            la prelucrarea datelor cu caracter personal și libera circulație a acestor date. Ne angajăm să prelucrăm
            datele dumneavoastră cu caracter personal în concordanță cu Regulamentul (UE) 679/2016 (GDPR), precum și cu
              orice altă legislație aplicabilă pe teritoriul României.</p>

            <p>Societatea este proprietară a platformei „funitservice.ro”, astfel încât toate informațiile cuprinse în acest
            material au legătură și sunt valabile în raport cu activitatea pe platforma „funitservice.ro”, denumită în
              continuare funitservice.ro sau site-ul.</p>

            <p>Pentru a înțelege mai bine politica noastră de protecție a datelor, vă invităm să citiți acest material.
            Ne rezervăm dreptul de a actualiza și modifica periodic această Politică de protecție a datelor cu caracter
            personal („Politica”) fără a vă cere acordul prealabil și fără a fi supuși unei proceduri de notificare
            individuală ori specială a modificărilor aduse. În cazul oricărei astfel de modificări, vom afișa pe site
            versiunea actualizată a Politicii de protecție a datelor cu caracter personal, rămânând în sarcina dumneavoastră
            să verificați conținutul acestei Politici ori de câte ori accesați site-ul, pentru a vă asigura că sunteți la curent
              cu ultima versiune.</p>

            <p>Vă stăm la dispoziție pentru informații și pe email suport@funitservice.ro.</p>

            <p class="h5"><b>1. Cine suntem?</b></p>
            <p>Calitatea de Operator de Date cu caracter personal o are XXXX SRL, cu sediul social
            în Alba Iulia, str. Tarnita, 5e, jud. Alba, înmatriculată la Oficiul Registrului
              Comerțului de pe lângă Tribunalul Alba sub nr. XXXX, având CUI 44540584 .</p>

            <p>Este posibil să transmitem datele personale pe care ni le furnizați către companii afiliate și/sau
            către alți parteneri și colaboratori cu care avem stabilite raporturi contractuale în vederea desfășurării
            activităților noastre și a îndeplinirii obligațiilor legale care ne revin. Transmiterea de date personale
            către terți are loc în baza unui angajament de confidențialitate din partea acestora și o asumare a
              asigurării protecției datelor.</p>

            <p class="h5"><b>2. Ce fel de date personale prelucrăm</b></p>
            <p>Datele personale pe care le prelucrăm sunt: nume, prenume, adresă, adresa de email, număr de telefon, IP.</p>

            <p>De asemenea, vă punem la dispoziție pe site o secțiune specială dedicată oferirii de testimoniale, respectiv
            materiale scrise însoțite sau nu de fotografia pe care o încărcați personal pe site sau prin intermediul
            site-urilor de socializare afiliate sau materiale audio sau video în care să expuneți experiența dumneavoastră
            raportat la serviciile de pe site și care pot fi utilizate de către Operator în scopul serviciilor de reclamă,
              marketing și publicitate a site-ului, inclusiv prin publicare pe acest site, dar și pe alte canale de promovare.</p>

           <p> Când vizitați site-ul, colectăm automat anumite informații despre dispozitivul dumneavoastră, cum ar fi
             informații despre browser, adresa IP, fus orar și unele cookie-uri instalate pe dispozitiv.</p>

            <p>Folosim Google Analytics pentru a ne ajuta să înțelegem cum utilizează clienții noștri site-ul - puteți
            citi mai multe despre modul în care Google utilizează informațiile dvs.
            personale aici: https://www.google.com/intl/ro/policies/privacy/. De asemenea, puteți renunța la Google
              Analytics aici: https://tools.google.com/dlpage/gaoptout.</p>

            <p>Vă rugăm să consultați și secțiunea privind Politica de cookie-uri afișată pe pagina principală a site-ului
              nostru</p>

            <p>Nu colectăm și nu prelucrăm date sensibile, definite ca atare de GDPR. Nu dorim să colectăm sau să
              prelucrăm date ale minorilor.</p>

            <p class="h5"><b> 3. Scopul pentru care prelucrăm date personale</b></p>
            <p>Solicităm și prelucrăm date personale pentru:</p>
<ul>
  <li> a face posibilă crearea contului dumneavoastră pe site și funcționarea site-ului</li>
  <li> transmiterea de newslettere, invitații, materiale legate de serviciile noastre și informații legate
    de evenimentele sau programele pe care le organizăm sau pe care le promovăm – dacă vă oferiți consimțământul
    în acest sens</li>
  <li>oferirea de răspunsuri la reclamațiile plasate</li>
  <li>evaluarea serviciilor oferite pe site</li>
  <li>monitorizarea vânzărilor și comportamentul Clienților, scopuri administrative, de media și altele asemenea -
    dacă vă oferiți consimțământul în acest sens</li>
  <li>comunicarea de oferte de bunuri și/sau servicii - dacă vă oferiți consimțământul în acest sens.</li>
</ul>
            <p class="h5"><b>4.Temeiurile prelucrării</b></p>
            <p>Operatorul are dreptul de a prelucra date personale pentru scopurile prezentate mai sus:</p>
<ul>
            <li>pentru că prelucrarea datelor este necesară pentru a face posibilă crearea contului, funcționarea
            site-ului și accesul la serviciile oferite pe site (încheierea contractului de prestări servicii la 2
            distanță), realizarea plății costurilor aferente serviciilor prestare și oferirea de răspunsuri la
              reclamațiile plasate, dacă este cazul,</li>
  <li>pentru că v-ați dat consimțământul expres în acest sens prin bifarea căsuțelor aferente</li>
</ul>
           <p> În cazul în care prelucrarea datelor nu este necesară pentru încheierea și executarea raporturilor
             noastre juridice, nu vom prelucra date personale pentru niciunul dintre scopurile de mai sus dacă nu
             avem consimțământul dumneavoastră în acest sens.</p>

            <p class="h5"><b> 5. Durata de stocare și prelucrare a datelor personale</b></p>
            <p>Prelucrăm și stocăm datele personale pe durata necesară îndeplinirii scopurilor pentru care datele au
              fost colectate și în orice caz pe perioada impusă de reglementările legale aplicabile.</p>

            <p>Actualizăm periodic baza noastră de date astfel încât să nu stocăm date care nu mai sunt necesare
              scopurilor pentru care au fost colectate.</p>

            <p class="h5"><b>6. Măsuri de securitate a datelor</b></p>
            <p>Societatea asigură măsurile tehnice și juridice adecvate pentru a asigura o protecție efectivă a
              datelor cu caracter personal aparținând persoanelor care ne furnizează astfel de date.</p>

            <p>Ne angajăm să vă păstrăm datele personale în siguranță și luăm măsurile rezonabile în acest sens,
            inclusiv împotriva accesului neautorizat, al utilizării neautorizate a datelor, distrugerii, pierderii
              sau alterării acestora.</p>

            <p>Ne instruim sistematic angajații legat de importanța protejării datelor cu caracter personal, am
            instituit proceduri interne în acest sens și, în plus, depunem toate eforturile pentru a ne asigura
            că și partenerii noștri gestionează măsuri adecvate pentru prelucrarea datelor personale care pot ajunge
              la ei în cadrul raporturilor noastre contractuale.</p>


            <p class="h5"><b> 7. Transmiterea datelor către alte persoane</b></p>
            <p>Este posibil să transmitem datele personale pe care ni le furnizați către companii afiliate și/sau către
            alți parteneri și colaboratori cu care avem stabilite raporturi contractuale în vederea desfășurării
            activităților noastre și a îndeplinirii obligațiilor legale care ne revin, cum ar fi: specialiștii care
            postează materiale pe site, furnizorii de servicii informatice, de servicii de contabilitate, de servicii
            juridice, de servicii de marketing, de servicii de curierat, de servicii de internet și telefonie mobilă,
            , specialiști în domeniul de
            activitate în care activăm, pe care îi contractăm pe a vă oferi o calitate cât mai bună a serviciilor
              noastre și alți asemenea furnizori.</p>

            <p>Transmiterea datelor către terțe persoane are loc sub rezerva asumării de către acestea a clauzelor de
              confidențialitate pe care le stabilim.</p>

           <p> De asemenea, putem transmite unele din datele personale colectate către autoritățile sau instituțiile
            publice competente, atunci când legea impune acest lucru sau către instanțele de judecată atunci când ne
             apărăm în justiție sau când ni se cere de către instanță acest lucru.</p>

            <p>Vă rugăm să consultați și secțiunea privind Politica de cookie-uri afișată pe pagina principală a site-ului
              nostru.</p>

            <p><b>Transferul datelor cu caracter personal în afara țării</b></p>

            <p>În contextul operațiunilor descrise mai sus, datele dumneavoastră cu caracter personal pot fi
              transferate în afara țării către state din Uniunea Europeana (“UE”) sau din Spatiul Economic European
              (“SEE”).</p>

            <p>Astfel, vă informăm că orice transfer efectuat de către Operator către un stat membru UE sau SEE va
              respecta prevederile legale din Regulamentul General privind protecția datelor nr. 2016/679 adoptat de
              Parlamentul European (“GDPR”).</p>

            <p>Datele cu caracter personal menționate în aceastănotăde informare nu sunt transferate către state care
              nu asigură protecție adecvatăîn ceea ce privește prelucrarea datelor cu caracter personal.</p>

            <p class="h5"><b>8. Ce drepturi aveți în legătură cu datele personale pe care ni le furnizați</b></p>
            <p>Ne angajăm să asigurăm securitatea datelor cu caracter personal prin adopta de măsuri tehnice și
            organizatorice adecvate, conform standardelor industriei. Platforma este aliniată la cerințele
            GDPR și folosește tehnologie de criptare și securizare a datelor la nivelul celor folosite de
              instituțiile bancare.</p>

            <p>Vă asigurăm și vă respectăm drepturile stabilite prin legislația în vigoare.</p>

            <p>Dreptul la informare – puteți solicita informații și detalii privind activitățile de prelucrare a datelor
            personale. Vă stăm la dispoziție la adresa de email: suport@upriserz.ro. Suntem atenți la asigurarea
            dreptului dumneavoastră de a primi informații clare, transparente, ușor de înțeles și accesibile cu
            privire la modul în care vă prelucrăm datele, inclusiv detalii legate de drepturile pe care le dețineți
              în acest sens și care sunt prezentate și în acest document.</p>

            <p>Dreptul la rectificare – în cazul în care constatați că datele dumneavoastră personale pe care
            le prelucrăm sunt incorecte, incomplete, inexacte, le puteți rectifica sau le puteți completa
              printro simplă solicitare de rectificare trimisă la adresa de email suport@funitservice.ro.</p>

            <p>Dreptul la stergerea datelor ("dreptul de a fi uitat") – puteți obtine ștergerea datelor, în cazul în care
            prelucrarea acestora nu a fost legală sau în alte cazuri prevăzute de lege, Ștergerea datelor poate avea
              loc în oricare dintre situațiile următoare:</p>

            <p>a) nu mai avem nevoie de datele personale pentru îndeplinirea vreunuia dintre scopurilor pentru care
              le-am prelucrat anterior;</p>

            <p>b) vă retrageți consimțământul pe baza căruia v-am prelucrat anterior și nu există un alt temei
              juridic pe care ne putem baza o prelucrare viitoare;</p>

            <p>c) vă opuneți prelucrării datelor atunci când prelucrăm datele în scop de marketing direct (inclusiv,
              profilarea în scop de marketing direct),</p>

            <p>d) vă opuneți prelucrării datelor bazat pe interesul nostru legitim și nu putem să demonstrăm că avem
            motive legitime care justifică prelucrarea și care prevalează asupra intereselor, drepturilor și
              libertăților dumneavoastră;</p>

            <p>e) datele personale sunt prelucrate contrar legii;</p>

            <p> f) datele personale trebuie șterse pentru a ne conforma obligațiilor noastre legale.</p>

            <p>Vă vom putea respinge solicitarea de ștergere a datelor dacă:</p>

            <p>a) trebuie să ne conformăm unor obligații legale de a păstra datele;</p>

            <p>b) dacă datele ne sunt necesare pentru constatarea, exercitarea sau apărarea drepturilor noastre în
              justiție.</p>

            <p>Dreptul la restricționarea prelucrării - puteți solicita restricționarea prelucrării în cazurile în care:</p>

            <p>a) contestați exactitatea datelor pe o perioadă care ne permite verificarea corectitudinii datelor;</p>

            <p>b) prelucrarea este ilegală, dar vă opuneți ștergerii datelor cu caracter personal, solicitând în schimb
              restricționarea;</p>

            <p>c) în cazul în care Operatorul nu mai are nevoie de datele cu caracter personal în scopul prelucrării,
            dar dumneavoastră ni le solicitați pentru constatarea, exercitarea sau apărarea unui drept în instanță sau
              în fața organelor de arbitraj;</p>

            <p>d) în cazul în care v-ați opus prelucrării, pentru intervalul de timp în care se verifică dacă drepturile
              noastre legitime prevalează asupra drepturilor dumneavoastră.</p>

            <p>Dreptul de opoziție – vă puteți opune în special, prelucrărilor de date care se întemeiază pe interesul
              nostru legitim.</p>

            <p>Dreptul la portabilitatea datelor - puteți primi, în anumite condiții, datele personale pe care ni le-ați
            furnizat, într-un format care poate fi citit automat sau puteți solicita ca respectivele date să fie transmise
            altui operator</p>

            <p>Dreptul de a depune plângere - puteți depune plângere fată de modalitatea de prelucrare a datelor personale la
            Autoritatea Natională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP). Date de contact și
            informații legate de ANSPDCP găsiți pe site-ul http://www.dataprotection.ro/.</p>

            <p>Dreptul de retragere a consimțământului – în cazurile în care prelucrarea se întemeiază pe consimțământul
            dvs., vi-l puteți retrage oricând. Retragerea consimțământului va avea efecte doar pentru viitor,
            prelucrarea efectuată anterior retragerii rămânând in continuare valabilă.</p>

            <p>Dreptul de a nu fi supus unor decizii automate sau profilare suplimentare aferente deciziilor automate:
            puteți cere și obține intervenția umană cu privire la respectiva prelucrare sau vă puteți exprima propriul
            punct de vedere cu privire la acest tip de prelucrare</p>

            <p>Vă puteți exercita aceste drepturi, fie individual, fie cumulat, foarte ușor, prin simpla transmitere a
            unei solicitări pe email la adresa suport@funitservice.ro.</p>

           <p class="h5"><b> 9. Datele de contact ale responsabilului cu protecția datelor
               XXXX, tel. XXXX, email: XXXX</b></p>

            <p class="h5"><b>10. Date ale minorilor</b></p>
            <p>Nu dorim să colectăm sau să prelucrăm date ale minorilor care nu au împlinit vârsta de 18 ani. </p>
            <p>Vă rugăm să nu vă creați cont pe site-ul nostru și să ne furnizați nicio informație în cazul în care nu
            aveți împlinită vârsta de 18 ani.</p>

            <p>Societatea are dreptul de a modifica Politica de protecție a datelor oricând, fără o notificare prealabilă,
            postând varianta actualizată pe site. Aveți obligația de a citi Termenii și condițiile, Politica de protecție
              a datelor și Politica de cookie-uri ori de câte ori accesați site-ul.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
