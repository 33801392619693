import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SwiperOptions} from 'swiper';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {Project} from './model/project.model';
import {ProjectService} from '../../project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit {
  data: Project;

  constructor(private route: ActivatedRoute, private projectService: ProjectService, private router: Router) { }

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    navigation: true,
    pagination: false
  };
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      900: {
        items: 4
      }
    },
    nav: false
  };

  ngOnInit(): void {
    const id = +this.route.snapshot.params.id;
    this.data = this.projectService.getProject(id);
    this.route.params.subscribe((params) => {
          this.data = this.projectService.getProject(+params.id);
        }
    );
  }

}
