<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Terms of Services </h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <p class="h4"> <b>Introduction:</b></p>
            <p> Utilizarea acestui site de către dumneavoastră este condiționată de respectarea termenilor și condițiilor generale
            detaliate mai jos. Dacă alegeți să vizitați site-ul și să accesați serviciile puse la dispoziție prin intermediul
            site-ului, vă asumați cunoașterea și acceptarea în totalitate a acestor termeni și condiții, care stau la baza
            contractului dintre Societate și dumneavoastră.</p>

            <p>Platforma funitservice.ro este deținută de către XXXX, cu sediul social în Alba Iulia,
            str. Tarnita, 5e, jud. Alba, înmatriculată la Oficiul Registrului Comerțului de pe
            lângă Tribunalul Alba sub nr. XXXX, având CUI 44540584, email contact@funit.com. Pentru facilitarea
            transmiterii informațiilor, vom avea în vederea utilizarea termenului de „Societate” pentru a ne referi la
            Fun It Service SRL, respectiv termenii „funitservice.ro” sau „site” pentru a ne referi la
            Platforma funitservice.ro</p>

            <p class="h5"><b>1. Ce este funitservice.ro</b></p>

            <p>XXXX este o platformă creată pentru facilitarea accesului online al publicului larg la XXXX(informatii)
              pe domeniul IT.</p>

            <p>Societatea va întreprinde toate acțiunile necesare pentru a pune la dispoziție clienților materiale cât
              mai calitative, actuale și relevante domeniului care face obiectul cursului.</p>

            <p>Vă informăm că platforma XXXX îndeplinește toate condițiile de conformitate, aceasta fiind în acord cu
            cerințele impuse de OUG nr. 141 din 28 decembrie 2021 privind anumite aspecte referitoare la contractele de
              furnizare de conținut digital și de servicii digitale.</p>

            <p> XXXX funcționează în termeni optimi și este compatibilă cu majoritatea navigatoarelor de internet
            (Google Chrome, Safari, Mozilla Firefox si Microsoft Edge). Cu toate acestea, Societatea nu poate garanta
            compatibilitatea Platformei cu toate echipamentele informatice, sistemele sau programe folosite de
            Utilizator, mai ales în situația în care nu sunt instalate ultimele actualizări a respectivelor
            sisteme/programe.</p>

            <p>Serviciile oferite prin intermediul site-ului sunt exclusiv cele aferente domeniilor de activitate
            prezentate pe prima pagină a site-ului. Pentru mai multe informații legate de serviciile disponibile
            pe Platformă,precum conținutul acestora, modalitatea de operare, preț etc. vă rugăm să verificați
              descrierea fiecărui serviciu prezentat pe site.</p>

            <p>Vă rugăm să țineți cont de faptul că serviciile pe care le puteți accesa prin intermediul site-ului nu
            reprezintă consultanță fiscală, contabilă, juridică sau altele asemenea și nu înlocuiesc serviciile de
              specialitate din astfel de domenii recomandate pentru orice antreprenor.</p>

            <p>Utilizatorii au posibilitatea de a descărca și de a utiliza aplicații XXXX Offline, disponibilă în magazinele
            Google Play și Apple Store. Precizăm că, achiziționarea și utilizarea aplicațiilor XXXX Offline,
            este condiționată de respectarea termenilor și condițiilor Google, respectiv Apple, motiv pentru care
              vă recomandăm să citiți cu atenție termenii și condițiile menționate.</p>

            <p class="h5"><b>2. Condiții pentru utilizarea site-ului</b></p>

            <p>Pentru a putea accesa produsele și serviciile puse la dispoziție prin intermediul site-ului, trebuie să vă
            creați un cont personal pe site. În acest scop, vă rugăm să utilizați butonul „Creeaza un cont” de pe
            pagina principală a site-ului și să parcurgeți în mod adecvat pașii necesari în vederea creării contului.
            Aveți obligația de a furniza informațiile care vă sunt solicitate și de a completa toate câmpurile în mod
              adecvat, complet, corect și real, așa cum acestea apar specificate în cuprinsul documentului de identitate.</p>

            <p>Contul poate fi accesat pe bază de parolă de acces, pe care o cunoașteți doar dumneavoastră.
            Sunteți pe deplin responsabil pentru păstrarea în siguranță a parolei de acces precum și a oricărei
            informații privind înregistrarea, autentificarea și contul. Un cont de utilizator asigură accesul în
            program în favoarea unei singure persoane. Aveți obligația de a nu transmite datele dumneavoastră de
              autentificare în favoarea unei terțe persoane.</p>

            <p>Poate să își creeze un cont pe site orice persoană fizică, indiferent de naționalitate,</p>

            <p>Site-ul poate fi utilizat doar pentru vizionare, creare cont, accesare cont personal, accesare produse/servicii
            existente pe site, pentru evaluarea și pentru recomandarea serviciilor noastre. Ne rezervăm dreptul de a nu permite
            postarea și/sau de a șterge imediat și fără vreo notificare prealabilă orice comentariu de pe site care conține
            un limbaj nepotrivit, erori grave de scriere sau care ar putea să ne aducă un prejudiciu de imagine și/sau să ne
            creeze o stare de disconfort, nouă, autorilor materialelor de pe site sau clienților și vizitatorilor.
              Sunteți pe deplin responsabil pentru orice operațiune efectuată din cont prin utilizarea site-ului.</p>

            <p>Nu putem garanta utilizarea discontinuă și neîntreruptă a site-ului. Este posibil să intervină erori, defecte,
            viruși sau alte comportamente dăunătoare pe site sau pe servere care să îl facă indisponibil. Vom lua toate măsurile
            pentru a limita și pentru a înlătura asemenea evenimente neplăcute în cel mai scurt timp posibil. Nu aveți dreptul
            să interveniți în operarea site-ului nostru și nici să luați vreo măsură de intervenție asupra echipamentului nostru
              informatic, indiferent de motiv și indiferent de rezultatul obținut.</p>

            <p>Ne rezervăm dreptul de a desființa site-ul fără o notificare prealabilă a utilizatorilor. Ne rezervăm dreptul de
            a ne stabili în mod liber relațiile de colaborare cu persoanele specializate care vor participa la crearea
            diverselor materiale de pe XXXX și nu ne asumăm faptul că aceste persoane vor avea o prezență constantă
              pe site.</p>

            <p class="h5"><b>3. Răspundere și Limitări</b></p>

            <p>Prin crearea și utilizarea Contului, vă asumați răspunderea pentru menținerea confidențialității datelor de Cont (user și parola) și
              pentru gestionarea accesării Contului, fiind responsabil în condițiile legii de activitatea derulata prin intermediul Contului.</p>

            <p>Prin accesarea site-ului, crearea Contului și utilizarea site-ului acceptați în mod expres și neechivoc Termenii și condițiile
              site-ului în ultima sa versiune comunicată în cadrul site-ului. Ulterior creării Contului, utilizarea conținutului echivalează cu
              acceptarea modificărilor intervenite asupra Termenilor și condițiilor site-ului și/sau a versiunilor actualizate ale Termenilor și Condițiilor
              Site-ului. Sunteți responsabil pentru verificarea versiunii finale a Termenilor și Condițiilor ori de câte ori utilizați site-ul.</p>

            <p>Acceptarea Termenilor și condițiilor site-ului se confirmă prin bifarea checkboxului corespunzător din site și/sau prin trimiterea Comenzii
              și/sau prin efectuarea unei plăți online.</p>

            <p>Societatea nu își asumă răspunderea pentru nici un fel de pierdere (materială, financiară, de date sau informații) care poate sa apară direct
              sau indirect din cauza informațiilor incluse pe sit sau a nefuncționării sau funcționarii necorespunzătoare a acestui site.</p>

            <p>Nu putem fi responsabili pentru daune aduse calculatorului dumneavoastră sau viruși care ar putea să vă infecteze calculatorul sau alt echipament
            ca urmare a accesării, utilizării de către dumneavoastră sau navigării pe site-ul nostru sau descărcarea de către dumneavoastră a vreunui conținut,
              informație, materiale, date, text, imagini, video sau audio de pe site-ul nostru.</p>

            <p>Nu suntem responsabili pentru orice prejudiciu, pierdere, revendicare, daune indirecte, incidente sau de consecință de orice tip, care decurge
            din sau are în vreun fel legătură cu orice utilizare a site-ului nostru sau a conținutului, datelor, materialelor sau informațiilor găsite în acesta,
            cu orice neîndeplinire sau întârziere (inclusiv fără limitare utilizarea sau incapacitatea de a utiliza orice componentă a acestui site pentru achiziționare
            sau plată), sau executarea sau neexecutarea de către noi sau de către orice furnizor, chiar dacă noi sau furnizorul nostru am fost avizați de posibilitatea
              de daune aduse acestor părți sau oricărei alte părți.</p>

            <p>Această exonerare de răspundere se aplică oricăror daune sau prejudicii cauzate de orice lipsă a performanței, eroare, omisiune, întrerupere,
            eliminare, defect, întârziere în operare sau transmisie, virus de calculator, defecțiune a liniei de comunicare, furt sau distrugere sau acces neautorizat
              la, modificarea, sau utilizarea înregistrării, fie pentru încălcarea contractului, comportament dăunător, neglijență, sau în baza oricărei cauze de acțiune.</p>

            <p class="h5"><b> 4. Reclamații</b></p>

            <p>Orice nemulțumire legată de accesarea, utilizarea, înregistrarea pe site-ul nostru, efectuarea unei comenzi, aspecte legate de comanda efectuată,
              aspecte legate de înscrierea la evenimente și alte asemenea, ne va fi comunicată direct, prin email la adresa suport@funitservice.ro.</p>

            <p>Nemulțumirea dvs. va fi înregistrată și veți primi un răspuns în scris, pe adresa de email menționată cu ocazia aducerii la cunoștința
              noastră a nemulțumirii dvs., în termen de cel mult 48 ore, raportate la o zi lucrătoare.</p>

            <p>Clientul declară că este de acord să nu facă publice aceste nemulțumiri (pe rețelele de socializare, media, discuții la petreceri private sau în orice
              altă modalitate) sub rezerva suportării daunelor cauzate pentru prejudiciul de imagine adus proprietarului site-ului prin aceste acțiuni.</p>

            <p>Orice reclamație se depune în termen de maxim o lună de la data sesizării situației reclamate.</p>

            <p class="h5"><b>5. Proprietate intelectuală</b></p>

            <p>Conținutul prezentului site, inclusiv, dar fără a se limita la logo, reprezentări stilizate, simboluri, imagini, fotografii, conținut texte și altele
            asemenea, precum și întreg conținutul informațiilor cuprinse pe funitservice.ro și a altor programe online comercializate prin intermediul site-ului sunt
            proprietatea exclusivă a Fun IT Service SRL. Este nepermisă copierea, distribuirea, publicarea, modificarea, completarea, utilizarea, expunerea,
            includerea, legarea, transmiterea, îndepărtarea însemnelor, fotografiilor, imaginilor, bucăților de text, afișarea, vinderea, etc, a conținutului, datelor,
            informațiilor, fotografiilor sau altor informații găsite pe site sau pe platformele/programele la care cumpărați accesul prin intermediul site-ului,
            fără permisiunea expresă acordată în scris de către Fun IT Service SRL. Nu este permis să transmiteți în favoarea unei terțe persoane parola dvs. de
              acces la funitservice.ro.</p>

            <p>Niciun Client nu dobândește, prin utilizarea și accesarea site-ului vreun drept sau vreo licență de utilizare a vreuneia dintre informațiile de pe site sau
            vreun drept de proprietate intelectuală/ industrială asupra produselor și/sau serviciilor comandate de pe site. Niciun client nu are dreptul de a utiliza
              un dispozitiv automat sau manual pentru a monitoriza materialele disponibile pe site.</p>

            <p class="h5"><b>6. Prelucrarea datelor cu caracter personal</b></p>

            <p>Pentru detalii referitoare la prelucrarea datelor cu caracter personal, identitatea operatorului și alte asemenea, consultați secțiunea „Politica de protecție
              a datelor” de pe site-ul nostru.</p>

            <p class="h5"><b>7.Newslettere</b></p>

            <p>Cu ocazia creării unui cont pe site Clientul are posibilitatea de a-și manifesta acordul cu privire la primirea de Newslettere.

            Newsletterul reprezintă un mijloc de informare periodic, exclusiv electronic (e-mail, SMS) asupra produselor, serviciilor, evenimentelor, promoțiilor, etc.
            dintr-o anumită perioadă, fără niciun angajament din partea proprietarului cu referire la informațiile conținute de acesta.

              Clientul își poate retrage acest acord oricând, fără nicio obligație, prin accesarea butonului Dezactivare din partea de jos a fiecărui Newsletter primit.</p>

            <p class="h5"><b>8. Forța majoră</b></p>

            <p>Nici una dintre părțile contractante nu răspunde de neexecutarea la termen sau/și de executarea în mod necorespunzător - total sau parțial - a oricărei
            obligații care îi revine în baza prezentului contract, daca neexecutarea sau executarea necorespunzătoare a obligației respective a fost cauzată de forța majoră,
              așa cum este definită de lege, din motive independente de părți.</p>

            <p>Partea care invocă forța majoră este obligată să notifice celeilalte părți, în termen de 5 (cinci) zile, producerea evenimentului și să ia toate măsurile
              posibile în vederea limitării consecințelor lui.</p>

            <p class="h5"><b>9. Legea aplicabilă</b></p>

            Contractul va fi guvernat și interpretat în conformitate cu legea română. Orice neînțelegere intervenită între proprietarul site-ului și Client în legătură
            cu raporturile decurgând din utilizarea site-ului urmează să fie rezolvate pe cale amiabilă, iar în caz de eșec, să fie supuse instanțelor competente material
            din Alba Iulia.

            <p class="h5"><b>10. Dispoziții finale</b></p>

            <p>Acest site este deținut de către Fun IT Service SRL, care vă oferă dreptul de a accesa și utiliza site-ul sub rezerva acceptării de către dumneavoastră a
              acestor Termeni și condiții. Accesând și utilizând site-ul vă oferit automat și neechivoc acordul pentru respectarea Termenilor și condițiilor de pe site.</p>

            <p>Fun IT Service SRL are dreptul de a modifica Termenii și condițiile oricând, fără o notificare prealabilă, postând varianta actualizată pe site.
            Aveți obligația de a citi Termenii și condițiile ori de câte ori accesați site-ul. Aveți obligația de a respecta întocmai Termenii și condițiile de pe
              site și nu puteți pretinde necunoașterea Termenilor și condițiilor de pe site, valabili la data accesării, utilizării și/sau plasării unei comenzi pe site.</p>

            <p>Administratorul site-ului își rezervă dreptul de a modifica structura si interfața oricărei pagini sau subpagini a site-ului in orice moment si la orice
            interval de timp liber ales, având dreptul de a întrerupe temporar, parțial sau în totalitate serviciile puse la dispoziția clienților prin intermediul acestui
            site fără vreo notificare prealabilă individuală sau generală.</p>

            <p>Prezenții Termeni și condiții se completează cu aspecte/prevederi speciale cuprinse la descrierea produselor/evenimentelor disponibile pe site. În cazul
              în care intervin contradicții între prezenții Termeni și condiții și prevederile speciale mai sus amintite, au prioritate de aplicare prevederile speciale.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
