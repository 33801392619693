import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-cookie',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})

/**
 * PAge Privacy Component
 */
export class CookiePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
