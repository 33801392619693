<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
         style="background: url('assets/img_funIt/bg2.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6" style="margin-bottom: 120px;">
        <div class="title-heading">
          <h4 class="text-white-50">{{ 'SECTION1.TITLE1' | translate }}</h4>
          <h4 class="heading text-white mb-3 title-dark"  style="white-space: pre-wrap"> {{ 'SECTION1.TITLE2' | translate }} </h4>
          <p class="para-desc text-white-50">{{ 'SECTION1.TITLE3' | translate }}</p>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">
        <img src="assets/img_funIt/Welcome.png" class="img-fluid" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section" id="whatwedo" >
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">{{'SECTION2.TITLE' | translate}}</h4>
          <p class="text-muted para-desc mb-0 mx-auto" [innerHTML]="'SECTION2.DETAILS' | translate"> </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div  class="col-lg-3 mb-4 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary " >
            <i class="uil uil-chart-line"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.PERFORMANCE.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">
              {{'SECTION2.PERFORMANCE.DETAILS' | translate}} </p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-chart-line"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 mb-4 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-crosshairs"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.SECURITIES.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.SECURITIES.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i classy="uil uil-crosshairs"></i>
          </span>
          <div></div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 mb-4 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-airplay"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.SERVICES.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.SERVICES.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-airplay"></i>
          </span>
        </div>
      </div>


      <div class="col-lg-3 mb-4 d-flex align-items-stretch">
        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket util"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.TECHNOLOGY.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.TECHNOLOGY.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-rocket"></i>
          </span>
        </div>
      </div>
      <!--end col-->
    </div>
    <div class="row">
      <div class="col-lg-3 mb-3 d-flex align-items-stretch">
        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-clock"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.SUPPORT.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.SUPPORT.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-clock"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 mb-3 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.MANAGMENT.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">
              {{'SECTION2.MANAGMENT.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-users-alt uil uil-users"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 mb-4 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-file-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.CERTIFIED.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.CERTIFIED.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-file-alt"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 mb-3 d-flex align-items-stretch">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0 equal-dim">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-search"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>{{'SECTION2.ANALYZE.TITLE' | translate}}</h5>
            <p class="para text-muted mb-0">{{'SECTION2.ANALYZE.DETAILS' | translate}}</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-search"></i>
          </span>
        </div>
      </div>

      <section> </section>
      <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" >
        <i-feather name="arrow-up" class="icons"></i-feather>
      </a>

    </div>
    <!--end row-->
  </div>
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>

</section>

<section class="section" id="whoweare" style="padding-top: 5px;" >
  <!-- About Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/img_funIt/img.jpg" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/img_funIt/img4.jpeg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/img_funIt/06.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">{{'SECTION3.TITLE' | translate}}</h4>
            <p class="text-muted para-desc">Start working with <span class="text-primary fw-bold">Fun IT Service</span> that
              </p>
            <p [innerHTML]="'SECTION3.DESCRIPTION' | translate" class="text-muted para-desc mb-0"></p>
          </div>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'SECTION3.SATISFIED' | translate}}</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'SECTION3.LANGUAGES' | translate}} </li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{'SECTION3.FUTURE' | translate}}</li>
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a href="#howwedowork" target="_blank" class="btn btn-primary m-1 me-2">Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
<!--            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"-->
<!--              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">-->
<!--              <i-feather name="video" class="icons"></i-feather>-->
<!--            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>-->
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section pt-0">
  <div class="container">
    <div class="p-4 rounded shadow bg-primary position-relative" style="z-index: 1;">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="progress-box">
            <h6 class="title text-light title-dark">Web Designing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:75%;">
                <div class="progress-value d-block text-light title-dark h6">75%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-light title-dark">Web Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:95%;">
                <div class="progress-value d-block text-light title-dark h6">95%</div>
              </div>
            </div>
          </div>
          <!--end process box-->

          <!--end process box-->
        </div>
        <!--end col-->

        <div class="col-md-6 col-12">
          <div class="progress-box mt-4 mt-sm-0">
            <h6 class="title text-light title-dark">Mobile Development</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:90%;">
                <div class="progress-value d-block text-light title-dark h6">90%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <div class="progress-box mt-4">
            <h6 class="title text-light title-dark">Desktop Marketing</h6>
            <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
              <div class="progress-bar position-relative bg-black" style="width:97%;">
                <div class="progress-value d-block text-light title-dark h6">97%</div>
              </div>
            </div>
          </div>
          <!--end process box-->
          <!--end process box-->
        </div>
        <!--end col-->
      </div>
      <!--end row -->
    </div>

    <div class="row mt-4 pt-2 position-relative" id="counter" style="z-index: 1;">
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/img_funIt/Asset190.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="99">3</span>%</h2>
          <h6 class="counter-head text-muted">{{'SECTION3.HAPPYCLIENTS' | translate}}</h6>
        </div>
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/img_funIt/clock.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" >{{hours}}</span>+</h2>
          <h6 class="counter-head text-muted">{{'SECTION3.HOURS' | translate}}</h6>
        </div>
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/img_funIt/star.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="10" [from]="0" [duration]="4"></span>+ years</h2>
          <h6 class="counter-head text-muted">{{'SECTION3.EXPERIENCE' | translate}}</h6>
        </div>
      </div>

      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/img_funIt/Asset187.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [CountTo]="98" [from]="3" [duration]="4"></span>%</h2>
          <h6 class="counter-head text-muted">{{'SECTION3.PROJECTS' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="feature-posts-placeholder bg-light"></div>
  </div>
</section>

<section class="section" id="howwedowork">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">{{'SECTION4.SUBTITLE' | translate}}</h6>
          <h4 class="title mb-4">{{'SECTION4.TITLE' | translate}}</h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4 mt-6 pt-2">
        <div class="card features feature-clean bg-transparent border-0 text-center work-process process-arrow-left" >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">{{'SECTION4.DISCUSSION.TITLE' | translate}}</h5>
            <p class="text-muted mb-0">
              {{'SECTION4.DISCUSSION.DETAILS' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">

        <div class="card features  feature-clean bg-transparent border-0 text-center process-arrow-4 work-process" >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">{{'SECTION4.REPORTING.TITLE' | translate}}</h5>
            <p class="text-muted mb-0">
              {{'SECTION4.REPORTING.DETAILS' | translate}}</p>
          </div>
        </div>
      </div>

      <div class="col-md-2 text-center mt-md-5 pt-md-5 mt-4 pt-2 ">
        <h3><span class="text-primary fw-bold">{{'SECTION4.WEEKS' | translate}}</span></h3>
      </div>

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean  bg-transparent border-0 text-center process-arrow-2 work-process">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">{{'SECTION4.IMPLEMENTING.TITLE' | translate}}</h5>
            <p class="text-muted mb-0">{{'SECTION4.IMPLEMENTING.DETAILS' | translate}}</p>
          </div>
        </div>
      </div>
      </div>


    <div class="row justify-content-center">
      <div class="col-md-4 mt-6 pt-2">
        <div class="card features feature-clean  bg-transparent border-0 text-center process-arrow-3 work-process" >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">{{'SECTION4.TESTING.TITLE' | translate}}</h5>
            <p class="text-muted mb-0">
              {{'SECTION4.TESTING.DETAILS' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">{{'SECTION5.TITLE' | translate}}</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-member style="margin-left: 294px" [memberData]="memberData"></app-member>
  </div>
  <!--end container-->
</section>

<section class="section bg-light" id="ourprojects">
  <div class="container">
    <div class="row align-items-center mb-4 pb-2">
      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <h4 class="title mb-4 mb-lg-0">{{'SECTION6.TITLE' | translate}}<br> </h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6">
        <div class="section-title text-center text-lg-start">
          <p class="text-muted mb-0 mx-auto para-desc"> <span
              class="text-primary fw-bold">{{'SECTION6.DESCRIPTION' | translate}}</span> </p>
        </div>
      </div>
      <!--end col-->
    </div>

    <app-blog [blogData]="blogData"></app-blog>
    <!--end row-->
  </div>
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">{{'SECTION6.TITLE2' | translate}}</h4>
        </div>
      </div>
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData" ></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section bg-light" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6  mt-4 pt-2">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">{{'SECTION7.TITLE' | translate}}</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">{{'SECTION7.NAME' | translate}}<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">{{'SECTION7.EMAIL' | translate}}<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{'SECTION7.SUBJECT' | translate}}</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Comments <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                  placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">{{'SECTION7.SEND' | translate}}</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-6 ps-md-3 pr-md-3 mt-4 pt-2">
        <div class="card map map-height-two rounded map-gray border-0">
          <div class="card-body p-0">
            <iframe
                    src="https://maps.google.com/maps?q=Strada%20Tarnita%205e,%20Alba%20Iulia&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    style="border:0" class="rounded" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


</section>

<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>

