<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Cookies Policy </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised:</span> 7th Jul,
              2022</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <p>Acest website folosește cookie-uri proprii și de la terți pentru a furniza vizitatorilor o experiență mult mai
              bună de navigare și servicii adaptate nevoilor și interesului fiecăruia.</p>

            <p class="h5"><b>1. Ce sunt „cookies”?</b></p>
            <p>Un „cookie” este un mic fișier, de regulă criptat, locat în dosarele navigatorului web pe care dvs. îl
            folosiți. „Cookie”-urile sunt create de către producătorii de conținut on-line pentru utilizatorii
            site-urilor în scopul de a-i ajuta pe aceștia să navigheze pe internet într-un mod mai eficient și pentru
              a acorda accesul la anumite funcții.</p>

            <p>Aceste fișiere nu sunt viruși. Cum aceste fișiere nu conțin coduri, ele nu pot fi executate și nici nu pot
            accesa alte fișiere sau dosare din calculatorul dvs. În condițiile în care ele stochează informații
            despre navigarea pe internet a unui utilizator, atât adrese specifice cât și navigare printre diverse
            site-uri, „cookie”-urile pot fi folosite ca o formă de spyware. Programele antispyware, fiind conștienți
              de această posibilă problemă, elimină anumite „cookie”-uri în urma controalelor de rutină.</p>

            <p class="h5"><b>2. De ce folosim „cookies”?</b></p>
            Utilizăm „cookie”-uri pentru a furniza utilizatorilor acestei pagini de internet o experiență mai bună de
            navigare și servicii adaptate nevoilor și interesului acestora, pentru analize statistice cu privire la
            modul în care este utilizată pagina de internet, în scopul îmbunătățirii site-ului. Cookieurile ne ajută la:

            personalizarea anumitor setari precum: limba în care este vizualizat un site, moneda în care se exprima anumite preîuri sau tarife, păstrarea opțiunilor pentru diverse produse/servicii
            ne oferă un feedback valoros asupra modului cum este utilizat site-ul de către utilizatori, astfel încât să îl putem face și mai eficient și mai accesibile pentru utilizatori
            permit aplicațiilor multimedia sau de alt tip de pe alte site-uri să fie incluse într-un anumit site pentru a crea o experiență de navigare mai valoroasă, mai utilă și mai plăcută
            îmbunatatesc eficiența publicității online
            Uneori, „cookie”-urile pot fi folosite pentru a ține urma vizitelor dvs. pe pagini de internet terțe.

            <p class="h5"><b>3. Ce fel de „cookie”-uri folosim?</b></p>
            <p>Din punct de vedere temporal al stocării folosim:</p>

            <p> - „Cookie”-uri fixe; acestea rămân pe dispozitivul utilizatorului o perioadă determinată de timp, specificată în „cookie” (de regulă, 2ani). Acestea sunt folosite de fiecare dată când utilizatorul vizitează adresa care a creat acel fișier.</p>
            <p> - „Cookie”-uri de sesiune; aceste fișiere sunt temporare. Ele permit operatorilor site-ului web să conecteze acțiunile pe care un utilizator le întreprinde în
              timpul unei sesiuni de navigare pe internet.</p>
            <p>De asemenea, din punct de vedere al funcționalității, folosim patru tipuri diferite de „cookie”-uri:</p>

            <p> - „Cookie”-uri strict necesare; acestea sunt esențiale pentru a putea naviga pe pagina de internet și pentru a-i
            folosi aplicațiile. În lipsa acestora, utilizatorii nu ar putea să folosească servicii de bază precum crearea unui
            cont sau folosirea unui coș de cumpărături. Aceste fișiere nu colectează informații despre utilizatori care ar putea
              fi folosite în scopuri de marketing sau urmărire a utilizatorului pe internet.</p>
            <p> - „Cookie”-uri de performanță; acestea colectează date statistice în mod anonim referitor la modul de utilizare
              al paginii de internet. Ele nu conțin informații personale și sunt folosite pentru a îmbunătății experiența
              utilizatorului atunci când navighează pe pagina de internet.</p>
            <p> - „Cookie”-uri pentru funcționalitate; prin intermediul lor, operatorul le permite utilizatorilor să
              aleagă diferite opțiuni și caracteristici ale paginii web. Spre exemplu, unele „cookie”-uri de funcționalitate
              sunt posibilitatea de a opta pentru memorarea parolei, preferințele de limbă, regiune, posibilitatea de a furniza
              informații personale precum locația actuală sau date despre vremea sau traficul din locația utilizatorului.</p>
            <p> - „Cookie”-uri publicitare și de targetting; folosite pentru a îmbuntăți relevanța reclamelor care vă sunt
              livrate. Acestea pot, de asemenea, să limiteze numărul de afișări ale unei reclame considerate irelevante sau
              să urmărească efectivitatea unei campanii publicitare prin menținerea unei statistice referitoare la numărul
              de accesări din partea utilizatorilor. De asemenea, o funcție importantă pe care unele dintre aceste „cookie”-uri
              o au este oferirea de securitate în cazul tranzacțiilor online. În mod obișnuit, aceste „cookie”-uri sunt folosite
              folosite de către terți operatori, putând să facă o conexiune între paginile web vizitate de utilizator, neavând
              însă posibilitatea să identifice identitatea utilizatorului, întrucât între profilul dvs. online și datele
              prelucrate nu se face nici o conexiune.
            <p> Avem „cookie”-uri terțe pe site-ul nostru. Terții, cum ar fi companiile de publicitate sau furnizorii de
            servicii externe, precum analiza traficului de internet, pot utiliza „cookie”-uri sau alte „plug-in” -uri
            similare pentru a colecta informații despre vizita dvs. pe pagina noastre web.</p>

            <p class="h5"><b>4. Cum puteți dezactiva „cookie”-urile?</b></p>
            <p>Pentru început ar trebui să știți că, odată cu ștergerea „cookie”-urilor, toate preferințele și setările
            referitoare la pagina web vor fi șterse. Asemenea, unele pagini web nu vor mai funcționa corespunzător sau
              unele funcții vor dispărea în cazul eliminării „cookie”-urilor.</p>

            <p>Nu recomandăm, din aceste motive, optarea respingerii „cookie”-urilor atunci când vizitați pagina noastră web.
              Cele mai multe browsere acceptă „cookie”-urile în mod automat, dar dvs. puteți schimba această opțiune în funcție
              de browserul pe care îl folosiți. Pentru a face asta, în mod obișnuit, trebuie să accesați opțiunea „setări” sau
              „preferințe” a browserului. </p>
             <p> Pentru mai multe detalii puteți accesa unul dintre linkurile de mai jos:</p>
            <ul>
              <li> Opera: https://www.opera.com/help/tutorials/security/cookies/</li>
              <li> Firefox: https://support.mozilla.com/en-US/kb/cookies-informationwebsites-store-on-your-computer?redirectlocale=enUS&redirectslug=Cookies</li>
              <li> Chrome: https://support.google.com/chrome/answer/95647?hl=en</li>
              <li> Safari: https://support.apple.com/en-us/HT201265</li>
            </ul>
            <p class="h5"><b>5. Securitate și probleme legate de confidentialitate</b></p>
            <p>Cookie-urile NU sunt viruși! Nu sunt alcătuite din bucăți de cod așa că nu pot fi executate nici nu pot
              auto-rula, nu se pot duplica sau replica pe alte rețele pentru a se rula sau replica din nou.</p>

            <p>În general, browserele au integrate setari de confidențialitate care furnizează diferite nivele de
            acceptare a cookie-rilor, perioada de valabilitate și ștergere automată după ce utilizatorul a vizitat
              un anumit site.</p>

            <p>Cookie-urile nu sunt periculoase, dar, pentru că prin intermediul lor se transmit în mod constant
              în ambele sensuri informații între browser și website, dacă un atacator sau persoană neautorizată
              intervine în parcursul de transmitere a datelor, informațiile continuțe de cookie pot fi interceptate.
              Desi foarte rar, acest lucru se poate întampla dacă browserul se conectează la server folosind o
              rețea necriptată (ex: o reteaWiFi nesecurizata).</p>

            <p>Alte atacuri bazate pe cookie implică setări greșite ale cookieurilor pe servere, care pot fi exploatate
              de către atacatori.</p>

            <p>Este important să cunoașteți că dezactivarea cookie-urilor nu va permite accesul utilizatorilor pe
              site-urile cele mai răspândite și utilizate prntre care Gmail, Yahoo, Youtube și altele. Există câteva
              măsuri de siguranță de bază pe care le puteți adopta pentru a naviga în siguranță cu ajutorul
              cookie-urilor.</p>

            <li> particularizați-vă setarilebrowserului în ceea ce priveste cookie-urile pentru a reflecta un nivel confortabil pentru voi al securității utilizării cookie-urilor.
              Puteți seta termene lungi de expirare pentru stocarea istoricului de navigare și al datelor personale de acces.</li>
            <p>Dacă utilizați calculatorul împreună cu alte persoane, puteți seta browserul pentru a șterge
              datele individuale de navigare de fiecare dată când închideți browserul.</p>

            <li>instalați-vă și updatati-vă constant aplicații antispyware</li>
            <li>asigurați-vă că aveți browserul mereu updatat; multe dintre atacurile bazate pe cookies se
              realizeaza exploatand punctele slabe ale versiunilor vechi ale browserelor.</li>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
