export class Project {
    constructor(
        public title: string,
        public subtitle: string,
        public description: string,
        public technologies: Array<string>,
        public images: string[],
        public badge: string[],
        public check: string[],
        public related: string[],
        public id2: number[],
        public imgRelated: string[],
        public linkSite: string
    ) { }
}
