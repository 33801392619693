import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, OnChanges, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

/***
 * It-Solution Component
 */

export class HomeComponent implements OnInit, AfterContentInit {
  /***
   * Nav Bg Class Add
   */
  navClass = 'nav-light';

  /**
   * Blog Data
   */
  blogData = [
    {
      image: 'assets/img_funIt/kurier1.jpg',
      title: 'Business web pages with multiple platforms',
      id: 1,
    },
    {
      image: 'assets/img_funIt/kurier1.jpg',
      title: 'Best mobile applications for Android & IOS for Business',
      id: 2,
    },
    {
      image: 'assets/img_funIt/kurier1.jpg',
      title: 'Smartest Applications for Business',
      id: 3,
    }
  ];

  /**
   * Member Data
   */
  memberData = [
    {
      number: 1,
      profile: 'assets/img_funIt/rares.jpg',
      name: 'Rareș Cutean',
      designation: 'C.E.O & Software Developer'
    },
    {
      number: 2,
      profile: 'assets/img_funIt/andrada4.jpg',
      name: 'Andrada Giurgiu',
      designation: 'Software Developer'
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: 'assets/img_funIt/parere01.jpg',
      name: 'Thomas Israel',
      designation: 'C.E.O',
      message: 'testimonial.client1'
    },
    {
      profile: 'assets/img_funIt/parere02.jpg',
      name: 'Barbara McIntosh',
      designation: 'M.D',
      message: 'testimonial.client2'
    },
    {
      profile: 'assets/img_funIt/parere03.jpg',
      name: 'Carl Oliver',
      designation: 'P.A',
      message: 'testimonial.client3'
    },
    {
      profile: 'assets/img_funIt/parere04.jpg',
      name: 'Christa Smith',
      designation: 'Manager',
      message: 'testimonial.client4'
    },
    {
      profile: 'assets/img_funIt/parere05.jpg',
      name: 'Dean Tolle',
      designation: 'Developer',
      message: 'testimonial.client5'
    },
    {
      profile: 'assets/img_funIt/parere06.jpg',
      name: 'Jill Webb',
      designation: 'Designer',
      message: 'testimonial.client6'
    }
  ];

  constructor(private modalService: NgbModal, private route: ActivatedRoute) {
  }

  hours: number;
  employee = 2;
  ngOnInit(): void {
    const startDate = new Date(2022, 6, 17);
    const endDate = new Date();
    this.hours = 8 * this.employee * this.numberOfWeekdays(endDate, startDate) + 26700;
  }

  ngAfterContentInit(): void {
    const elementId = this.route.snapshot.fragment;
    setTimeout(function () { document.getElementById(elementId).scrollIntoView(); }, 500);
  }

  numberOfDaysInclusive(d0: Date, d1: Date): number{
    return 1 + Math.round((d1.getTime() - d0.getTime()) / (24 * 3600 * 1000));
  }

  numberOfWeekends(d0: Date, d1: Date): number {
    const days = this.numberOfDaysInclusive(d0, d1); // total number of days
    const sundays: number = Math.floor((days + (d0.getDay() + 6) % 7) / 7); // number of sundays
    // @ts-ignore
    return 2 * sundays + (d1.getDay() === 6) - (d0.getDay() === 0);
    // multiply sundays by 2 to get both sat and sun,
    // +1 if d1 is saturday, -1 if d0 is sunday
  }

  numberOfWeekdays(d0: Date, d1: Date): number {
    return this.numberOfDaysInclusive(d0, d1) - this.numberOfWeekends(d0, d1);
  }


  openWindowCustomClass(content): void {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  mapView(content): void {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
}

