import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import {MasterPageComponent} from './core/components/master-page/master-page.component';
import {ProjectComponent} from './core/components/project/project.component';
import {PageTermsComponent} from './core/components/page-terms/page-terms.component';
import {PagePrivacyComponent} from './core/components/page-privacy/page-privacy.component';
import {CookiePolicyComponent} from './core/components/cookie-policy/cookie-policy.component';

const routes: Routes = [
  {
    path: '', component: MasterPageComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'project/:id', component: ProjectComponent},
      { path: 'page-terms', component: PageTermsComponent},
      { path: 'page-privacy', component: PagePrivacyComponent},
      { path: 'page-cookie', component: CookiePolicyComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
